$dark: #00748C;
$dark-color-font: #FFFFFF;
$medium: #3F96A8;
$medium-color-font: $dark-color-font;
$light: #7FB9C5;
$light-color-font: $dark-color-font;
$info: #009CA6;
$info-color-font: $dark-color-font;
$cancel: #FB4D6D;
$action: #52d2bc;
$disclaimerColor: #4a4a4a;
$disableColor: #b5b5b5;
$result: #bfdce2;
$result-color-font: #000000;
$result-button-color-font: $dark;
$errorInput: #f2dede;
$grey-background: #efefef;
$grey-font: #979797;
$border-grey-color: #E5E5E5;

$font-size-xsmall: 10px;
$font-size-small: 11px;
$font-size-normal: 13px;
$font-size-medium: 15px;
$font-size-large: 18px;
$font-size-big: 20px;
$font-size-extrabig: 25px;
$font-size-xextrabig: 30px;
$font-size-xxextrabig: 55px;

$font-size-mobile-xsmall: 7px;
// $font-size-mobile-small: 11px;
$font-size-mobile-medium: 10px;
$font-size-mobile-large: 16px;
$font-size-mobile-big: 15px;
$font-size-mobile-extrabig: 21px;
$marginTopList: 100 50 30 20 15 10;
$marginTopImportantList: 100 150 170;
$marginBottomList: 10 20 25;
$marginRightList: 5 10 20;
$marginLeftList: 5 10 15 20;
$paddingLeftList: 25 30;
$paddingRightList: 15 25 30;
$paddingTopList: 0 5 15 30;
$tileTextPadding: 15px;
$stepFormSeparation: 15px;
$stepFormBlock: 20px;
$toolsUnderlineWidth: 2px;
$z-index-slider-ball: 15;
$z-index-bar: 20;
$z-index-input-control: 25;