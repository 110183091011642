.fintupHeader {
  text-align: center;
  background: #00748D;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fintupHeader img{
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.fintupHeader .userData {
  float: right;
  font-size: 13px;
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
}

.fintupHeader .userData p{
  margin-bottom: 5px;
  color: white;
  font-weight: bold;
}

@media (max-width: 767px) {
  .fintupHeader {
    
  }
}