.loadingApp {
  height: 100vh;
  display: flex;
}

.loadingApp.noFullHeight {
  height: inherit;
  display: flex;
}

.loadingApp .sk-spinner {
  width: 150px;
  height: 150px;
  color: #379ebd;
  margin: auto;
}

.loadingApp .sk-spinner > div{
  width: 150px;
  height: 150px;
}