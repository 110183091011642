.singleFormContainer.mainContainer.singleBody{
  min-height: calc(100vh - 50px);
}

.singleFormContainer.mainContainer{
    min-height: calc(100vh - 117px);
    background-color: white;
    border-color: white;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 90px;
    padding-right: 75px;
    min-height: -o-calc(100vh - 100px); /* opera */
    min-height: -webkit-calc(100vh - 100px); /* google, safari */
    min-height: -moz-calc(100vh - 100px); /* firefox */
    
}

.singleFormContainer.mainContainer .row{
  display: block;
}

.singleFormContainer .form-container {
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    /*border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);*/
    border-radius: 6px;
    outline: 0;
    /*-webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);*/
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 25px;
    padding: 35px;
    width: 60%;
    margin: auto;
}

@media (max-width: 767px) {
  .singleFormContainer .form-container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    width: auto;
    padding: 0px;
  }

  .singleFormContainer.mainContainer{
    padding-left: 50px;
    padding-right: 40px;
  }
}

.singleFormContainer h2{
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 700;
  text-align: center;
}

.singleFormContainer .formLegend{
  margin-bottom: 15px;
  text-align: center;
  color: #00748D;
}

.singleFormContainer .alert{
  margin-bottom: 5px;
}