@import url('https://fonts.googleapis.com/css?family=Raleway');

/*body{
  overflow: auto !important;
  position: initial !important;
}*/

#root {
  font-family: 'Raleway', sans-serif;
}
.fintup.fintupHeaderContainer .appContent{
  min-height: calc(100vh - 117px);
  min-height: -o-calc(100vh - 117px); /* opera */
  min-height: -webkit-calc(100vh - 117px); /* google, safari */
  min-height: -moz-calc(100vh - 117px); /* firefox */
}

h1{
  font-size: 28px;
}

h2{
  font-size: 22px;
}

h3{
  font-size: 18px;
}

.font-size-normal{
  font-size: 14px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.row-block{
  display: block;
}

.flex-items{
  display: flex;
}

.flex-items-vertical-center{
  align-items: center;
}

.margin-left{
  margin-left: 20px;
}

.margin-top{
  margin-top: 20px;
}

.margin-bottom{
  margin-bottom: 20px;
}

.margin-top-xs{
  margin-top: 10px;
}

.margin-bottom-xs{
  margin-bottom: 10px;
}

.card{
  border: 1px solid #999;
  border-radius: 5px;
  padding: 10px;
}

h1.title-page{
  font-weight: bold;
}

a{
  cursor: pointer;
}

/* Second column: clear padding */
.col-xs-12 .col-xs-12{
  padding-left: 0px;
  padding-right: 0px;
}

h2.subtitle-page{
  font-weight: bold;
}

h3.subtitle-page{
  font-weight: bold;
}

.container-fluid {
  padding: 0;
}

.backButton{
  margin-top: 20px;
  margin-bottom: 20px;
}

.fa-1_5x{
  font-size: 1.5em;
}

.fa-0_75x{
  font-size: 0.75em;
}

.font-weight-normal{
  font-weight: 400 !important;
}

.font-weight-bold{
  font-weight: 700 !important;
}

.text-decoration-underline{
  text-decoration: underline;
}

.overflow-break-word{
  overflow-wrap: break-word;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.bgGrey{
  background:#f5f5f5; 
}

.titleGrey {
  color:#7b7b7b;
}

.documentUploadContainer{
  display: flex;
  align-items: center;
}

.greyColor {
  color: #8a8a8a;
}

.borderTopRound {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  outline: 0;
}

body{
  color: #2d2d2d;
}

.marginTop50 {
  margin-top: 50px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBotton10 {
  margin-bottom: 10px;
}

.marginBotton25 {
  margin-bottom: 25px;
}

.marginRight10{
  margin-right: 10px;
}

.marginRight20{
  margin-right: 20px;
}

.marginLeft5{
  margin-left: 5px;
}

.marginLeft10{
  margin-left: 10px;
}

.paddingLeft25 {
  padding-left: 25px;
}

.paddingLeft30 {
  padding-left: 30px;
}

.paddingRight25 {
  padding-right: 25px;
}

.paddingRight30 {
  padding-right: 30px;
}

.paddingTop0 {
  padding-top: 0px!important;
}

.paddingTop5 {
  padding-top: 5px!important;
}

.paddingTop15 {
  padding-top: 15px;
}

.paddingTop30 {
  padding-top: 30px;
}

.imageStack {
  position: relative;
}

.imageStack .imageBackgroung{
  position: absolute;
}

.imageStack .mainImage{
  position: absolute;
}

.clearAll{
	clear: both;
}

.fRight{
  float: right;
}

.fLeft{
  float: left;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.flex-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex-wrapper-start {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-item {
  width: 100%;
}

.flex-item-inherit {
  width: inherit;
}

.fintup .formColumn {
  padding-right: 0px;
}

.flexHorizontalCenter {
  display: flex; /* establish flex container */
  flex-direction: row; /* make main axis horizontal (default value) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center; /* center items vertically, in this case */
}

.fintupStrong {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  box-shadow: inset 0 -5px 0 0px #f363;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fintupStrongGreen {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(233, 253, 240, 1), rgba(233, 253, 240, 1));
  -webkit-transition: .5s background-image linear;
  transition: .5s background-image linear;
  cursor: pointer;
}

.fintupStrongGreen:active, .fintupStrongGreen:focus, .fintupStrongGreen:hover {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(125, 255, 179, 1), rgba(125, 255, 179, 1));
  -webkit-transition: .5s background-image linear;
  transition: .5s background-image linear;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 767px) {
  .show-xs{
    display: none;
  }
}

/* Extra small devices (767px and down) */
@media (max-width: 767px) {
    .row.vertical-align {
        display: block; /* Turn off the flexible box layout */
    }

    .paddinLeft15-xs {
      padding-left: 25px;
    }

    .marginBotton15-xs {
      margin-bottom: 15px;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .row.vertical-align {
        display: flex;
        align-items: center;
    }

    .row.flex-row {
      display: flex;
    }
}
