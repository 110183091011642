@import '../../styles/_colors.scss';

.historyView{

  .button-save-header{
    display: inline;

    .btn-fintup{
      margin-bottom: 5px;
    }
  }

  .row{
    align-items: flex-start;

    .labelAdviceClient .newStyleGrid{
      padding: 0px !important;
      margin-top: 20px;
    }

    .col-xs-12 .col-xs-12{
      padding-left: 10px;
      padding-right: 10px;
    }

    //Fix height
    .selectLabel, .inputTextLabel{
      height: 80px;
    }
  }

  .datePicker{
    padding-left: 15px;
    padding-right: 15px;
  }

  .title-subsection{
    display: block;
    font-weight: bold;
    font-size: $font-size-mobile-large;
  }

  //Product and contract
  .buttonDeleteRow{
    height: 64px;
    display: flex;
    color: $cancel;
    font-weight: bold;
    font-size: 20px;
    align-items: flex-end;
    /* justify-content: center;*/
    cursor: pointer;
  }


  .buttonDeleteObjective{
    display: flex;
    color: $cancel;
    font-weight: bold;
    font-size: 20px;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
  }

  ion-segment{
    //--background: #fff;

    ion-segment-button{
      --background-checked: #00748C;
      --color-checked	: #fff;
      --indicator-color: transparent;
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (max-width: 768px) {
     .content-header{
      display: block;
     }
  }
}

