@import url(https://fonts.googleapis.com/css?family=Raleway);
.footer-bottom{
  background-color: #00748D;
  color: #FFFFFF;
  line-height: 50px;
  font-size: 11px;
  text-align: center;
  letter-spacing: 2px;
  word-spacing: 3px;
}

.footer-bottom .row{
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .footer-bottom{
    line-height: 30px;
  }
}
.hideAlert{
  display: none;
}
.singleFormContainer.mainContainer.singleBody{
  min-height: calc(100vh - 50px);
}

.singleFormContainer.mainContainer{
    min-height: calc(100vh - 117px);
    background-color: white;
    border-color: white;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 90px;
    padding-right: 75px;
    min-height: -o-calc(100vh - 100px); /* opera */ /* google, safari */
    min-height: -moz-calc(100vh - 100px); /* firefox */
    
}

.singleFormContainer.mainContainer .row{
  display: block;
}

.singleFormContainer .form-container {
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    /*border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);*/
    border-radius: 6px;
    outline: 0;
    /*-webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);*/
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 25px;
    padding: 35px;
    width: 60%;
    margin: auto;
}

@media (max-width: 767px) {
  .singleFormContainer .form-container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    width: auto;
    padding: 0px;
  }

  .singleFormContainer.mainContainer{
    padding-left: 50px;
    padding-right: 40px;
  }
}

.singleFormContainer h2{
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 700;
  text-align: center;
}

.singleFormContainer .formLegend{
  margin-bottom: 15px;
  text-align: center;
  color: #00748D;
}

.singleFormContainer .alert{
  margin-bottom: 5px;
}
.socialLogin .react-google-oauth-button-login {
  height: 36px;
}

.socialLogin .react-google-oauth-button-iconWrapper{
  padding-top: 8px;
  padding-bottom: 8px;
}

.socialLogin .react-google-oauth-button-span {
  line-height: 36px;
}

.socialLogin {
  text-align: center;
  margin-bottom: 15px;
}
.inputTextLabel {
  /* enable absolute positioning */
  /* align icon */ }
  .inputTextLabel .required-label {
    color: #FB4D6D;
    font-weight: 700;
    font-size: 14px;
    margin-left: 5px; }
  .inputTextLabel label {
    margin-bottom: 10px; }
  .inputTextLabel .form-group.row label {
    margin-bottom: 5px;
    margin-top: 5px; }
  .inputTextLabel .form-group.row label.rightLabel {
    margin-top: 0px; }
  .inputTextLabel .form-group.row label.rightLabel.dividerLabel {
    border-left-color: silver;
    border-left-width: 1px;
    border-left-style: solid;
    padding-left: 10px; }
  .inputTextLabel label.htmlLabel {
    font-weight: inherit; }
  .inputTextLabel .appendText {
    /*margin-left: -15px;*/ }
  .inputTextLabel .form-control {
    border-radius: 0px; }
  .inputTextLabel input.form-control::-webkit-input-placeholder {
    font-weight: 700; }
  .inputTextLabel input.form-control::placeholder {
    font-weight: 700; }
  .inputTextLabel .inner-addon {
    position: relative;
    /* style icon */ }
    .inputTextLabel .inner-addon .addonContent {
      position: absolute;
      padding: 10px;
      /* pointer-events: none; */ }
  .inputTextLabel .left-addon .addonContent {
    left: 0px; }
  .inputTextLabel .left-addon input {
    padding-left: 50px; }
  .inputTextLabel .right-addon .addonContent {
    right: 0px; }
  .inputTextLabel .right-addon input {
    padding-right: 50px; }
  .inputTextLabel .addonContent .fintupIcons {
    margin-left: 5px;
    cursor: pointer; }
  .inputTextLabel .form-control:focus {
    border-color: #52d2bc;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #52d2bc; }
  .inputTextLabel.innerInput .form-control {
    border-color: #00748C;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C; }
  .inputTextLabel.innerInput .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #00748C;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.invest .form-control {
    border-color: #00748C;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C; }
  .inputTextLabel.innerInput.invest .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #00748C;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.pensionPlan .form-control {
    border-color: #3F96A8;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #3F96A8; }
  .inputTextLabel.innerInput.pensionPlan .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #3F96A8;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.saving .form-control {
    border-color: #7FB9C5;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #7FB9C5; }
  .inputTextLabel.innerInput.saving .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #7FB9C5;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }

/*Small and down*/
@media (max-width: 767px) {
  .inputTextLabel.innerInput .form-group.row label {
    padding-top: 7px;
    padding-bottom: 8px; } }

@charset "UTF-8";
@font-face {
  font-family: fintupIcons;
  src: url("https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/fonts/fintupIcons.woff"); }

.CircleIcon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #00748C;
  display: flex;
  background-color: #00748C;
  color: white;
  align-items: center;
  justify-content: center; }
  .CircleIcon--small {
    width: 20px;
    height: 20px;
    font-size: 17px; }
  .CircleIcon__icon {
    display: flex;
    flex: 1 1;
    border-radius: 100%;
    justify-content: center;
    align-items: center; }

.fintupIcons {
  font-family: fintupIcons; }
  .fintupIcons.okColor {
    color: #00748C; }
  .fintupIcons.pensionPlanColor {
    color: #3F96A8; }
  .fintupIcons.savingColor {
    color: #7FB9C5; }
  .fintupIcons.cancelColor {
    color: #FB4D6D; }

.fintupIcons.icon-size-75 {
  font-size: 75px; }

.fintupIcons.icon-size-40 {
  font-size: 40px; }

.fintupIcons.icon-size-35 {
  font-size: 35px; }

.fintupIcons.icon-size-30 {
  font-size: 30px; }

.fintupIcons.icon-size-20 {
  font-size: 20px; }

.fintupIcons.icon-size-15 {
  font-size: 15px; }

.fintupIcons.icon-size-10 {
  font-size: 10px; }

.fintupIcons.icon-size-8 {
  font-size: 8px; }

.fintupIcons.icon-weight-500 {
  font-weight: 500; }

.fintupIcons.altavoz:before {
  content: "\E93A"; }

.fintupIcons.bell:before {
  content: "\E901"; }

.fintupIcons.exit:before {
  content: "\E902"; }

.fintupIcons.analyze:before {
  content: "\E903"; }

.fintupIcons.academia:before {
  content: "\E904"; }

.fintupIcons.check-done:before {
  content: "\E93B"; }

.fintupIcons.refresh:before {
  content: "\E93C"; }

.fintupIcons.test:before {
  content: "\E93D"; }

.fintupIcons.simulador:before {
  content: "\E93E"; }

.fintupIcons.contratar:before {
  content: "\E905"; }

.fintupIcons.herramientas:before {
  content: "\E906"; }

.fintupIcons.midinero:before {
  content: "\E907"; }

.fintupIcons.miperfil:before {
  content: "\E908"; }

.fintupIcons.retirar:before {
  content: "\E90B"; }

.fintupIcons.pensiones:before {
  content: "\E90C"; }

.fintupIcons.inversion:before {
  content: "\E91D"; }

.fintupIcons.info:before {
  content: "\E91E"; }

.fintupIcons.graphic:before {
  content: "\E91F"; }

.fintupIcons.graphicCircular:before {
  content: "\E920"; }

.fintupIcons.aportar:before {
  content: "\E921"; }

.fintupIcons.plus:before {
  content: "\E900"; }

.fintupIcons.ahorro:before {
  content: "\E934"; }

.fintupIcons.cancel:before {
  content: "\E909"; }

.fintupIcons.check:before {
  content: "\E90A"; }

.fintupIcons.arrow-right:before {
  content: "\E932"; }

.fintupIcons.arrow-left:before {
  content: "\E933"; }

.fintupIcons.camera:before {
  content: "\E935"; }

.fintupIcons.flecha-seleccionable-izquierda:before {
  content: "\E936"; }

.fintupIcons.flecha-seleccionable-derecha:before {
  content: "\E937"; }

.fintupIcons.flecha-seleccionable-arriba:before {
  content: "\E938"; }

.fintupIcons.flecha-seleccionable-abajo:before {
  content: "\E939"; }

.fintupIcons.euroDown:before {
  content: "\E93F"; }

.fintupIcons.mundo:before {
  content: "\E940"; }

.fintupIcons.infinito:before {
  content: "\E941"; }

.fintupIcons.brujula:before {
  content: "\E942"; }

.fintupIcons.libro:before {
  content: "\E944"; }

.fintupIcons.promediar:before {
  content: "\E943"; }

.fintupIcons.actualidad:before {
  content: "\E945"; }

.fintupIcons.articulo:before {
  content: "\E946"; }

.fintupIcons.corazon:before {
  content: "\E947"; }

.fintupIcons.filosofia:before {
  content: "\E948"; }

.fintupIcons.play:before {
  content: "\E949"; }

.fintupIcons.cloud:before {
  content: "\E94A"; }

.fintupIcons.phone:before {
  content: "\E94B"; }

.fintupIcons.mail:before {
  content: "\E94C"; }

.fintupIcons.invite:before {
  content: "\E94D"; }

.fintupIcons.inviteplus:before {
  content: "\E94E"; }

.fintupIcons.copy:before {
  content: "\E950"; }

.fintupIcons.circle:before {
  content: "\E951"; }

.fintupIcons.circle-plus:before {
  content: "\E94F"; }

.fintupIcons.circle-minus:before {
  content: "\E951"; }

.fintupIcons.pdf-icon:before {
  content: "\E954"; }

.fintupIcons.jpg-icon:before {
  content: "\E952"; }

.fintupIcons.png-icon:before {
  content: "\E953"; }

.fintupIcons.dni-anverso:before {
  content: "\E956"; }

.fintupIcons.dni-reverso:before {
  content: "\E955"; }

.fintupIcons.file:before {
  content: "\E957"; }

.fintupIcons.inversion-coloreado .path1:before {
  content: "\E90D";
  color: #00748c; }

.fintupIcons.inversion-coloreado .path2:before {
  content: "\E90E";
  color: #fff;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path3:before {
  content: "\E90F";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path4:before {
  content: "\E910";
  color: #00748c;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path5:before {
  content: "\E911";
  color: #00748c;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path6:before {
  content: "\E912";
  color: #00748c;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path7:before {
  content: "\E913";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path8:before {
  content: "\E914";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path9:before {
  content: "\E915";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path10:before {
  content: "\E916";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path11:before {
  content: "\E917";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path12:before {
  content: "\E918";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path13:before {
  content: "\E919";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path14:before {
  content: "\E91A";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path15:before {
  content: "\E91B";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path16:before {
  content: "\E91C";
  color: #000;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path1:before {
  content: "\E922";
  color: #000; }

.fintupIcons.ahorro-coloreado .path2:before {
  content: "\E923";
  color: #00748c;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path3:before {
  content: "\E924";
  color: #fff;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path4:before {
  content: "\E925";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path5:before {
  content: "\E926";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path6:before {
  content: "\E927";
  color: #000;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path7:before {
  content: "\E928";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path8:before {
  content: "\E929";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path9:before {
  content: "\E92A";
  color: #3f96a8;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path10:before {
  content: "\E92B";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path11:before {
  content: "\E92C";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path12:before {
  content: "\E92D";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path13:before {
  content: "\E92E";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path14:before {
  content: "\E92F";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path15:before {
  content: "\E930";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path16:before {
  content: "\E931";
  color: #00748c;
  margin-left: -1em; }

.fintupButton .btn-m {
  min-width: 100px; }

.fintupButton .btn-full {
  width: 100%; }

.fintupButton .sk-spinner {
  margin: auto;
  color: #00748C; }

.fintup .btn-l {
  min-width: 150px; }

.fintup .btn-2l {
  min-width: 310px; }

.fintup .btn-xl {
  min-width: 200px; }

.fintup .fintupButton .btn-xxl {
  min-width: 260px; }

.fintup .fintupButton .btn-xxxl {
  min-width: 350px; }

.fintupButton.btn-loading .login-color {
  background-color: #758f96 !important;
  border-color: #758f96 !important; }

.fintupButton .btn:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -0px; }

.fintupButton .btn.btnSelector {
  border-radius: 10px;
  border-color: #00748C;
  border-style: solid;
  border-width: 2px;
  background-color: #00748C;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px; }

.fintupButton .btn.btnStepCard {
  min-width: 200px;
  border-radius: 25px;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 20px; }

.fintupButton .btn-fintup {
  color: #fff;
  background-color: #52d2bc;
  border-color: #52d2bc;
  border-radius: 25px; }

.fintupButton .btnAddRow {
  color: #000000;
  font-size: 14px;
  border-width: 0px;
  font-weight: 600;
  background-color: transparent;
  text-decoration: underline; }

.fintupButton .btn-bold {
  font-size: 16px;
  font-weight: 800;
  padding-top: 10px;
  padding-bottom: 10px; }

.fintupButton .btn-red {
  color: #FFFFFF;
  border-color: #FB4D6D;
  background-color: #FB4D6D; }

.home .separator {
  float: left;
  width: 45%;
}

.home .separator hr{
  margin-top: 10px;
}


.home .separatorCenter {
  float: left;
  width: 10%;
  text-align: center;
  color: #00748D;
}

.historyView {
  /* Small devices (tablets, 768px and up) */ }
  .historyView .button-save-header {
    display: inline; }
    .historyView .button-save-header .btn-fintup {
      margin-bottom: 5px; }
  .historyView .row {
    align-items: flex-start; }
    .historyView .row .labelAdviceClient .newStyleGrid {
      padding: 0px !important;
      margin-top: 20px; }
    .historyView .row .col-xs-12 .col-xs-12 {
      padding-left: 10px;
      padding-right: 10px; }
    .historyView .row .selectLabel, .historyView .row .inputTextLabel {
      height: 80px; }
  .historyView .datePicker {
    padding-left: 15px;
    padding-right: 15px; }
  .historyView .title-subsection {
    display: block;
    font-weight: bold;
    font-size: 16px; }
  .historyView .buttonDeleteRow {
    height: 64px;
    display: flex;
    color: #FB4D6D;
    font-weight: bold;
    font-size: 20px;
    align-items: flex-end;
    /* justify-content: center;*/
    cursor: pointer; }
  .historyView .buttonDeleteObjective {
    display: flex;
    color: #FB4D6D;
    font-weight: bold;
    font-size: 20px;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer; }
  .historyView ion-segment ion-segment-button {
    --background-checked: #00748C;
    --color-checked: #fff;
    --indicator-color: transparent; }
  @media (max-width: 768px) {
    .historyView .content-header {
      display: block; } }

.operationData.row {
  border-style: solid;
  border-width: 0px;
  border-color: #f5f5f5;
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px;
  border-bottom-style: dotted;
}

.operationData.row:last-child {
  border-bottom: 0px;
}

.operationData .labelContainer {
  color: black;/*#8a8a8a;*/
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 0px;
  font-weight: 700;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0px;
}

.operationData .valueContainer {
  margin-top: 5px;
  margin-bottom: 3px;
}

.operationData .colContainer {
  padding-right: 0px;
  padding-left: 0px;
}
.editLabel .changeAliasProductIcon {
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.editLabel .changeAliasProductIconEdit {
  margin-right: 5px;
  margin-top: 5px;
  font-size: 25px;
}

.inputTextArea textarea {
  resize: vertical;
  resize: none;
  overflow: hidden;
  min-height: 80px;
  max-height: 400px; }

.inputTextArea .required-label {
  color: #FB4D6D;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px; }

.inputTextArea label {
  margin-bottom: 10px; }

.inputTextArea .form-group.row label {
  margin-bottom: 5px;
  margin-top: 5px; }

.inputTextArea .form-group.row label.rightLabel {
  margin-top: 0px; }

.inputTextArea .form-group.row label.rightLabel.dividerLabel {
  border-left-color: silver;
  border-left-width: 1px;
  border-left-style: solid;
  padding-left: 10px; }

.inputTextArea label.htmlLabel {
  font-weight: inherit; }

.inputTextArea .appendText {
  /*margin-left: -15px;*/ }

.inputTextArea .form-control {
  border-radius: 0px; }

.inputTextArea input.form-control::-webkit-input-placeholder {
  font-weight: 700; }

.inputTextArea input.form-control::placeholder {
  font-weight: 700; }

.inputTextArea .form-control:focus {
  border-color: #52d2bc;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #52d2bc; }

/*Small and down*/
@media (max-width: 767px) {
  .inputTextLabel.innerInput .form-group.row label {
    padding-top: 7px;
    padding-bottom: 8px; } }

.selectLabel .required-label{
  color: red;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
}

.selectLabel label{
  margin-bottom: 10px;
}

.selectLabel label.htmlLabel{
  font-weight: inherit
}

.selectLabel .form-group.row label.rightLabel{
  margin-top: 0px;
}

.selectLabel .form-group.row label.rightLabel.dividerLabel{
  border-left-color: silver;
  border-left-width: 1px;
  border-left-style: solid;
}

.selectLabel .Select.is-disabled div.Select-control{
  background-color: #eee;
}
.datePicker .datePickerLabel {
  margin-bottom: 10px;
}

.datePicker .react-datepicker__year-read-view--down-arrow, 
.datePicker .react-datepicker__month-read-view--down-arrow, 
.datePicker .react-datepicker__month-year-read-view--down-arrow {
  top: 10px;
}

 .datePicker .form-control[readonly] {
    background-color: #fff;
 }


 .react-datepicker {
  font-size: 1.5rem !important;
}

.react-datepicker__current-month {
  font-size: 1.7rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.7rem !important;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}
.cookiesAdvise{
  direction: ltr;
  color: #8a8a8a;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  background-color: #ddd;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cookiesAdvise a{
  cursor: pointer;
}

.cookiesAdvise a:hover{
  color: #00748c!important;
}

.cookiesAdvise a:focus{
  outline-color: transparent!important;
  text-decoration: none;
}

@media (max-width: 767px) {
  .cookiesAdvise .cookiesAdviseButton {
    margin-top: 10px;
    text-align: center;
  }
}

.fintupHeader {
  text-align: center;
  background: #00748D;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fintupHeader img{
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.fintupHeader .userData {
  float: right;
  font-size: 13px;
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
}

.fintupHeader .userData p{
  margin-bottom: 5px;
  color: white;
  font-weight: bold;
}

@media (max-width: 767px) {
  .fintupHeader {
    
  }
}

/*body{
  overflow: auto !important;
  position: initial !important;
}*/

#root {
  font-family: 'Raleway', sans-serif;
}
.fintup.fintupHeaderContainer .appContent{
  min-height: calc(100vh - 117px);
  min-height: -o-calc(100vh - 117px); /* opera */ /* google, safari */
  min-height: -moz-calc(100vh - 117px); /* firefox */
}

h1{
  font-size: 28px;
}

h2{
  font-size: 22px;
}

h3{
  font-size: 18px;
}

.font-size-normal{
  font-size: 14px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.row-block{
  display: block;
}

.flex-items{
  display: flex;
}

.flex-items-vertical-center{
  align-items: center;
}

.margin-left{
  margin-left: 20px;
}

.margin-top{
  margin-top: 20px;
}

.margin-bottom{
  margin-bottom: 20px;
}

.margin-top-xs{
  margin-top: 10px;
}

.margin-bottom-xs{
  margin-bottom: 10px;
}

.card{
  border: 1px solid #999;
  border-radius: 5px;
  padding: 10px;
}

h1.title-page{
  font-weight: bold;
}

a{
  cursor: pointer;
}

/* Second column: clear padding */
.col-xs-12 .col-xs-12{
  padding-left: 0px;
  padding-right: 0px;
}

h2.subtitle-page{
  font-weight: bold;
}

h3.subtitle-page{
  font-weight: bold;
}

.container-fluid {
  padding: 0;
}

.backButton{
  margin-top: 20px;
  margin-bottom: 20px;
}

.fa-1_5x{
  font-size: 1.5em;
}

.fa-0_75x{
  font-size: 0.75em;
}

.font-weight-normal{
  font-weight: 400 !important;
}

.font-weight-bold{
  font-weight: 700 !important;
}

.text-decoration-underline{
  text-decoration: underline;
}

.overflow-break-word{
  overflow-wrap: break-word;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.bgGrey{
  background:#f5f5f5; 
}

.titleGrey {
  color:#7b7b7b;
}

.documentUploadContainer{
  display: flex;
  align-items: center;
}

.greyColor {
  color: #8a8a8a;
}

.borderTopRound {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  outline: 0;
}

body{
  color: #2d2d2d;
}

.marginTop50 {
  margin-top: 50px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBotton10 {
  margin-bottom: 10px;
}

.marginBotton25 {
  margin-bottom: 25px;
}

.marginRight10{
  margin-right: 10px;
}

.marginRight20{
  margin-right: 20px;
}

.marginLeft5{
  margin-left: 5px;
}

.marginLeft10{
  margin-left: 10px;
}

.paddingLeft25 {
  padding-left: 25px;
}

.paddingLeft30 {
  padding-left: 30px;
}

.paddingRight25 {
  padding-right: 25px;
}

.paddingRight30 {
  padding-right: 30px;
}

.paddingTop0 {
  padding-top: 0px!important;
}

.paddingTop5 {
  padding-top: 5px!important;
}

.paddingTop15 {
  padding-top: 15px;
}

.paddingTop30 {
  padding-top: 30px;
}

.imageStack {
  position: relative;
}

.imageStack .imageBackgroung{
  position: absolute;
}

.imageStack .mainImage{
  position: absolute;
}

.clearAll{
	clear: both;
}

.fRight{
  float: right;
}

.fLeft{
  float: left;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.flex-wrapper {
  display: flex;
  justify-content: space-around;
}

.flex-wrapper-start {
  display: flex;
  justify-content: flex-start;
}

.flex-item {
  width: 100%;
}

.flex-item-inherit {
  width: inherit;
}

.fintup .formColumn {
  padding-right: 0px;
}

.flexHorizontalCenter {
  display: flex; /* establish flex container */
  flex-direction: row; /* make main axis horizontal (default value) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center; /* center items vertically, in this case */
}

.fintupStrong {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  box-shadow: inset 0 -5px 0 0px #f363;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

.fintupStrongGreen {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(233, 253, 240, 1), rgba(233, 253, 240, 1));
  transition: .5s background-image linear;
  cursor: pointer;
}

.fintupStrongGreen:active, .fintupStrongGreen:focus, .fintupStrongGreen:hover {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(125, 255, 179, 1), rgba(125, 255, 179, 1));
  transition: .5s background-image linear;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 767px) {
  .show-xs{
    display: none;
  }
}

/* Extra small devices (767px and down) */
@media (max-width: 767px) {
    .row.vertical-align {
        display: block; /* Turn off the flexible box layout */
    }

    .paddinLeft15-xs {
      padding-left: 25px;
    }

    .marginBotton15-xs {
      margin-bottom: 15px;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .row.vertical-align {
        display: flex;
        align-items: center;
    }

    .row.flex-row {
      display: flex;
    }
}

.loadingApp {
  height: 100vh;
  display: flex;
}

.loadingApp.noFullHeight {
  height: inherit;
  display: flex;
}

.loadingApp .sk-spinner {
  width: 150px;
  height: 150px;
  color: #379ebd;
  margin: auto;
}

.loadingApp .sk-spinner > div{
  width: 150px;
  height: 150px;
}
