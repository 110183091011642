.datePicker .datePickerLabel {
  margin-bottom: 10px;
}

.datePicker .react-datepicker__year-read-view--down-arrow, 
.datePicker .react-datepicker__month-read-view--down-arrow, 
.datePicker .react-datepicker__month-year-read-view--down-arrow {
  top: 10px;
}

 .datePicker .form-control[readonly] {
    background-color: #fff;
 }


 .react-datepicker {
  font-size: 1.5rem !important;
}

.react-datepicker__current-month {
  font-size: 1.7rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.7rem !important;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}