.editLabel .changeAliasProductIcon {
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.editLabel .changeAliasProductIconEdit {
  margin-right: 5px;
  margin-top: 5px;
  font-size: 25px;
}
