.home .separator {
  float: left;
  width: 45%;
}

.home .separator hr{
  margin-top: 10px;
}


.home .separatorCenter {
  float: left;
  width: 10%;
  text-align: center;
  color: #00748D;
}
