@import './_colors.scss';

$formTileMinHeightOffset: 190px;

$formTileMinHeight: calc(100vh - #{$formTileMinHeightOffset});
$oformTileMinHeight: -o-calc(100vh - #{$formTileMinHeightOffset});
$wformTileMinHeight: -webkit-calc(100vh - #{$formTileMinHeightOffset}); 
$mformTileMinHeight: -moz-calc(100vh - #{$formTileMinHeightOffset}); 

@mixin globalPositionTileForm() {
  min-height: $formTileMinHeight;
  min-height: $oformTileMinHeight;
  min-height: $wformTileMinHeight;
  min-height: $mformTileMinHeight;
}

@mixin innerInputMixin($color, $fontColor) {
  .form-control {
    border-color: $color;
    outline: 0;
    -webkit-box-shadow: inset 0 0px 0px rgba(0,0,0,.075), 0 0 0px $color;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075), 0 0 0px $color;
  }

  .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: $color;
    color: $fontColor;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

