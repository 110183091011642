@import '../../../styles/_colors.scss';
@import '../../../styles/_mixins.scss';

.inputTextArea {

  textarea{
    resize: vertical;
    resize: none;
    overflow: hidden;
    min-height: 80px;
    max-height: 400px;
  }

  .required-label{
    color: $cancel;
    font-weight: 700;
    font-size: 14px;
    margin-left: 5px;
  }

  label{
    margin-bottom: 10px;
  }

  .form-group.row {
    
    label{
      margin-bottom: 5px;
      margin-top: 5px;
    }

    label.rightLabel{
      margin-top: 0px;
    }

    label.rightLabel.dividerLabel{
      border-left-color: silver;
      border-left-width: 1px;
      border-left-style: solid;
      padding-left: 10px;
    }
  }

  label.htmlLabel{
    font-weight: inherit
  }

  .appendText {
    /*margin-left: -15px;*/
  }
  
  .form-control {
    border-radius: 0px;
  }
  
  input.form-control::placeholder {
    font-weight: 700;
  }



  //Candidatos a mixins
  .form-control:focus {
    border-color: $action;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $action;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $action;
  }

}

/*Small and down*/
@media (max-width: 767px) {
  .inputTextLabel.innerInput .form-group.row label {
    padding-top: 7px;
    padding-bottom: 8px;
  }
}