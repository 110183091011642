.socialLogin .react-google-oauth-button-login {
  height: 36px;
}

.socialLogin .react-google-oauth-button-iconWrapper{
  padding-top: 8px;
  padding-bottom: 8px;
}

.socialLogin .react-google-oauth-button-span {
  line-height: 36px;
}

.socialLogin {
  text-align: center;
  margin-bottom: 15px;
}