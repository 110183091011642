@import '../../../styles/_colors.scss';

.fintupButton {
    .btn-m {
        min-width: 100px;
    }
    
    .btn-full {
        width: 100%;
    }

    .sk-spinner {
        margin: auto;
        color: $dark;
    }
}



.fintup .btn-l {
    min-width: 150px;
}

.fintup .btn-2l {
    min-width: 310px;
}

.fintup .btn-xl {
    min-width: 200px;
}

.fintup .fintupButton .btn-xxl {
    min-width: 260px;
}

.fintup .fintupButton .btn-xxxl {
    min-width: 350px;
}

.fintupButton.btn-loading .login-color{
    background-color: #758f96 !important;
    border-color: #758f96 !important;
}

.fintupButton .btn:focus{
    outline:0px auto -webkit-focus-ring-color;
    outline-offset:-0px
}

.fintupButton .btn.btnSelector {
    border-radius: 10px;
    border-color: $dark;
    border-style: solid;
    border-width: 2px;
    background-color: $dark;
    color: $dark-color-font;
    font-weight: 700;
    font-size: 20px;
}

.fintupButton .btn.btnStepCard {
    min-width: 200px;
    border-radius: 25px;
    border-color: #000000;
    border-style: solid;
    border-width: 1px;
    background-color: $dark-color-font;
    color: #000000;
    font-size: 20px;
}

.fintupButton .btn-fintup {
    color: #fff;
    background-color: #52d2bc;
    border-color: #52d2bc;
    border-radius: 25px;
    
}

.fintupButton .btnAddRow{
    color: $result-color-font;
    font-size: 14px;
    border-width: 0px;
    font-weight: 600;
    background-color: transparent;
    text-decoration: underline;
}


.fintupButton .btn-bold {
    font-size: 16px;
    font-weight: 800;
    padding-top: 10px;
    padding-bottom: 10px;
}

.fintupButton .btn-red{
    color: $dark-color-font;
    border-color: $cancel;
    background-color: $cancel;
}


