.cookiesAdvise{
  direction: ltr;
  color: #8a8a8a;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  background-color: #ddd;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cookiesAdvise a{
  cursor: pointer;
}

.cookiesAdvise a:hover{
  color: #00748c!important;
}

.cookiesAdvise a:focus{
  outline-color: transparent!important;
  text-decoration: none;
}

@media (max-width: 767px) {
  .cookiesAdvise .cookiesAdviseButton {
    margin-top: 10px;
    text-align: center;
  }
}
