.footer-bottom{
  background-color: #00748D;
  color: #FFFFFF;
  line-height: 50px;
  font-size: 11px;
  text-align: center;
  letter-spacing: 2px;
  word-spacing: 3px;
}

.footer-bottom .row{
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .footer-bottom{
    line-height: 30px;
  }
}