.operationData.row {
  border-style: solid;
  border-width: 0px;
  border-color: #f5f5f5;
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px;
  border-bottom-style: dotted;
}

.operationData.row:last-child {
  border-bottom: 0px;
}

.operationData .labelContainer {
  color: black;/*#8a8a8a;*/
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 0px;
  font-weight: 700;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0px;
}

.operationData .valueContainer {
  margin-top: 5px;
  margin-bottom: 3px;
}

.operationData .colContainer {
  padding-right: 0px;
  padding-left: 0px;
}