.selectLabel .required-label{
  color: red;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
}

.selectLabel label{
  margin-bottom: 10px;
}

.selectLabel label.htmlLabel{
  font-weight: inherit
}

.selectLabel .form-group.row label.rightLabel{
  margin-top: 0px;
}

.selectLabel .form-group.row label.rightLabel.dividerLabel{
  border-left-color: silver;
  border-left-width: 1px;
  border-left-style: solid;
}

.selectLabel .Select.is-disabled div.Select-control{
  background-color: #eee;
}